import {
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'

import {
  ArrowDownCircleIcon,
  PlusCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { IconButton, Tooltip } from '@mui/material'
import axios from 'axios'

import { toast } from '@redwoodjs/web/toast'

import Loading from 'src/components/Library/Loading/Loading'

interface CellFileRenderProps {
  field: any // not typed yet
  cellValue: any // not typed yet
  setCellValue: Dispatch<SetStateAction<any>> // not typed yet
  uploadFile: any // not typed yet - BR function
}

export const CellFileRender: FC<CellFileRenderProps> = ({
  field,
  cellValue,
  setCellValue,
  uploadFile,
}) => {
  const [recordFileThumbnails, setRecordFileThumbnails] = useState([])
  const [fileExpandOpen, setFileExpandOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  const [expandedFile, setExpandedFile] = useState<File>()
  const [confirmDeleteFile, setConfirmDeleteFile] = useState<number>()
  const [fileFlip, setFileFlip] = useState(false)
  const [uploadingFiles, setUploadingFiles] = useState(false)

  const fileInputRef = useRef(null)

  useEffect(() => {
    const fetchFiles = async () => {
      if (field.type === 'file') {
        const recordFileObjects = cellValue
        const files = []
        for (const fileObject of recordFileObjects) {
          if (fileObject?.thumbnails) {
            const response = await axios.get(fileObject.thumbnails.small.url, {
              responseType: 'blob',
            })
            const file = new File([response.data], fileObject.visible_name, {
              type: response.headers['content-type'],
            })
            const id = fileObject.name
            files.push({
              id,
              file,
              hasThumbnail: true,
              mimeType: fileObject.mime_type,
            })
          } else {
            files.push({
              id: fileObject.name,
              file: {
                name: fileObject.visible_name,
              },
              hasThumbnail: false,
              mimeType: fileObject.mime_type,
            })
          }
        }
        setRecordFileThumbnails(files)
      }
    }
    fetchFiles()
  }, [field, cellValue])

  useEffect(() => {
    const fetchFile = async () => {
      if (selectedFile) {
        const recordFileObject = cellValue.find((fileObject) => {
          return fileObject.name === selectedFile
        })
        const response = await axios.get(recordFileObject.url, {
          responseType: 'blob',
        })
        const file = new File([response.data], recordFileObject.visible_name, {
          type: response.headers['content-type'],
        })
        setExpandedFile(file)
      }
    }
    fetchFile()
  }, [selectedFile, recordFileThumbnails, fileFlip])

  const handleNextFile = () => {
    const currentIndex = recordFileThumbnails.findIndex(
      (file) => file.id === selectedFile,
    )
    const nextIndex = (currentIndex + 1) % recordFileThumbnails.length
    setSelectedFile(recordFileThumbnails[nextIndex].id)
  }

  const handlePreviousFile = () => {
    const currentIndex = recordFileThumbnails.findIndex(
      (file) => file.id === selectedFile,
    )
    const previousIndex =
      (currentIndex - 1 + recordFileThumbnails.length) %
      recordFileThumbnails.length
    setSelectedFile(recordFileThumbnails[previousIndex].id)
  }

  const handleFileUpload = async (event) => {
    setUploadingFiles(true)
    const newFiles = []
    for (const file of event.target.files) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await uploadFile(formData)

      if (response) {
        newFiles.push({
          ...response,
          visible_name: file.name,
        })
      } else {
        toast.error('There was an error uploading your file.', {
          duration: 5000,
        })
      }
    }
    setCellValue([...cellValue, ...newFiles])
    setUploadingFiles(false)
  }

  const deleteFile = (fileObject) => {
    const newFiles = cellValue.filter((file) => file?.name !== fileObject?.id)
    setCellValue(newFiles)
  }

  const deleteFileExpand = (fileObject) => {
    const newFiles = cellValue.filter((file) => file?.name !== fileObject?.name)
    setCellValue(newFiles)
  }

  return (
    <>
      <div className="flex flex-wrap gap-2">
        {recordFileThumbnails.map((fileObject, index) => (
          <Tooltip title={fileObject.file.name} key={index} arrow>
            <div
              className="p-2 border-gray-300 border rounded-lg flex flex-col gap-1 items-center hover:bg-gray-100 relative w-24"
              onClick={() => {
                setFileExpandOpen(true)
                setSelectedFile(fileObject.id)
                setFileFlip(!fileFlip)
              }}
              role="button"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setFileExpandOpen(true)
                  setSelectedFile(fileObject.id)
                  setFileFlip(!fileFlip)
                }
              }}
              tabIndex={0}
            >
              <div
                className={`absolute -top-2  bg-white rounded-full w-4 h-4 flex items-center justify-center cursor-pointer ${
                  confirmDeleteFile === index ? 'right-2' : '-right-2'
                }`}
              >
                {confirmDeleteFile !== index && (
                  <XCircleIcon
                    className="w-4 h-4 text-gray-500 hover:text-red-500"
                    onClick={(e) => {
                      e.stopPropagation()
                      setConfirmDeleteFile(index)
                    }}
                  />
                )}
                {confirmDeleteFile === index && (
                  <button
                    className="bg-red-500 text-white px-1 rounded-md text-xs z-10"
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteFile(fileObject)
                    }}
                  >
                    <p>Delete</p>
                  </button>
                )}
              </div>
              <div
                className={
                  'absolute bottom-0 right-0  bg-white rounded-full w-4 h-4 flex items-center justify-center cursor-pointer'
                }
              >
                <a
                  href={
                    cellValue.find((file) => file.name === fileObject.id)?.url
                  }
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const url = cellValue.find(
                      (file) => file.name === fileObject.id,
                    ).url
                    const fileName = fileObject.file.name

                    const response = await fetch(url)
                    const blob = await response.blob()
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = fileName
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    URL.revokeObjectURL(link.href)
                  }}
                  rel="noreferrer"
                >
                  <ArrowDownCircleIcon className="w-4 h-4 text-gray-500 hover:text-green-500" />
                </a>
              </div>
              <div className="w-16">
                {fileObject.hasThumbnail && (
                  <img
                    src={URL.createObjectURL(fileObject.file)}
                    alt="thumbnail"
                    className="w-16 h-16 object-cover rounded-lg"
                  />
                )}
                {!fileObject.hasThumbnail && (
                  <div className="w-16 h-16 flex items-center justify-center">
                    <p className="uppercase text-gray-500 p-1 bg-gray-100 rounded-lg">
                      {fileObject.mimeType.split('/')[1]}
                    </p>
                  </div>
                )}
              </div>
              <div className="w-20">
                <p className="text-xs text-gray-500 truncate">
                  {fileObject.file.name}
                </p>
              </div>
            </div>
          </Tooltip>
        ))}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          multiple
        />
        <button
          className="p-2 border-gray-400 border-2 rounded-lg flex flex-col gap-1 items-center hover:bg-gray-100 relative w-24"
          onClick={() => fileInputRef.current.click()}
          disabled={uploadingFiles}
        >
          {uploadingFiles && (
            <div className="w-16 h-20 mb-1">
              <Loading />
            </div>
          )}
          {!uploadingFiles && (
            <>
              <div className="w-16 h-16 flex items-center justify-center">
                <PlusCircleIcon className="w-12 h-12 text-gray-400" />
              </div>
              <div className="w-20">
                <p className="text-xs text-gray-600 truncate">Upload</p>
              </div>
            </>
          )}
        </button>
      </div>
      {fileExpandOpen && (
        <div
          className="full h-screen w-full bg-black bg-opacity-50 fixed top-0 left-0 flex justify-center z-50"
          onClick={() => {
            setExpandedFile(null)
            setFileExpandOpen(false)
            setConfirmDeleteFile(null)
          }}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
        >
          {expandedFile && (
            <div
              className="h-full flex flex-col gap-4 items-center justify-center end"
              onClick={(e) => {
                e.stopPropagation()
              }}
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <div className="flex w-[1000px] ">
                <div className=" ml-auto">
                  <p className="text-white text-2xl">
                    {expandedFile?.name || 'Loading...'}
                  </p>
                </div>
                <div className="flex gap-4 ml-auto">
                  <button
                    onClick={() => {
                      setExpandedFile(null)
                      setFileExpandOpen(false)
                      deleteFileExpand(
                        cellValue.find((file) => file.name === selectedFile),
                      )
                    }}
                    className="hover:bg-red-400 cursor-pointer rounded-lg"
                  >
                    <TrashIcon className="h-10 w-10 text-white" />
                  </button>
                  <a
                    href={
                      cellValue.find((file) => file.name === selectedFile).url
                    }
                    onClick={async (e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      const url = cellValue.find(
                        (file) => file.name === selectedFile,
                      ).url
                      const fileName = expandedFile.name

                      const response = await fetch(url)
                      const blob = await response.blob()
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = fileName
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                      URL.revokeObjectURL(link.href)
                    }}
                    rel="noreferrer"
                  >
                    <ArrowDownCircleIcon className="h-10 w-10 text-white" />
                  </a>
                  <button
                    onClick={() => {
                      setExpandedFile(null)
                      setFileExpandOpen(false)
                    }}
                    className="hover:bg-gray-400 cursor-pointer rounded-lg"
                  >
                    <XMarkIcon className="h-10 w-10 text-white" />
                  </button>
                </div>
              </div>
              <div className="flex gap-4 w-full">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handlePreviousFile()
                  }}
                >
                  <ChevronLeftIcon className="text-white h-24 w-24" />
                </IconButton>
                <div className="h-[90vh] w-[1000px] bg-white rounded-lg flex justify-center items-center">
                  {expandedFile.type.startsWith('image/') && (
                    <img
                      src={URL.createObjectURL(expandedFile)}
                      alt="thumbnail"
                      className="w-full h-full object-contain rounded-lg"
                    />
                  )}
                  {expandedFile.type === 'application/pdf' && (
                    <embed
                      src={URL.createObjectURL(expandedFile)}
                      type="application/pdf"
                      className="w-full h-full rounded-lg"
                    />
                  )}
                  {expandedFile.type.startsWith('video/') && (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video controls className="w-full h-full rounded-lg">
                      <source
                        src={URL.createObjectURL(expandedFile)}
                        type={expandedFile.type}
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {expandedFile.type.startsWith('audio/') && (
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <audio controls className="w-full h-full rounded-lg">
                      <source
                        src={URL.createObjectURL(expandedFile)}
                        type={expandedFile.type}
                      />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                  {expandedFile.type === 'text/html' && (
                    <iframe
                      src={URL.createObjectURL(expandedFile)}
                      title="Expanded File Preview"
                      className="w-full h-full rounded-lg"
                    />
                  )}
                  {!expandedFile.type.startsWith('image/') &&
                    expandedFile.type !== 'application/pdf' &&
                    !expandedFile.type.startsWith('video/') &&
                    !expandedFile.type.startsWith('audio/') &&
                    !expandedFile.type.startsWith('text/') &&
                    expandedFile.type !== 'text/html' && (
                      <div className="w-full h-full flex flex-col justify-center items-center">
                        <p className="text-gray-500 text-xl">
                          No preview available for this file type.
                        </p>
                      </div>
                    )}
                </div>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleNextFile()
                  }}
                >
                  <ChevronRightIcon className="text-white h-24 w-24" />
                </IconButton>
              </div>
            </div>
          )}
          {!expandedFile && <Loading />}
        </div>
      )}
    </>
  )
}
