/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import type { FC } from 'react'

import { CheckIcon } from '@heroicons/react/24/outline'
import { Rating, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { NameInitialsAvatar } from 'react-name-initials-avatar'
import tinycolor from 'tinycolor2'

import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import Button from 'src/components/Library/Button/Button'
import { formatDurationValue } from 'src/lib/baserow/modules/database/utils/duration-1.25.1'
import { openWindowWithBlockCheck } from 'src/lib/helpers'

import { BaserowFieldType, BaserowFormulaType } from './lib/enums'

interface ColoredChipProps {
  data: {
    value: string
    color: string
  }
  isName: boolean
}
export const ColoredChip: FC<ColoredChipProps> = ({ data, isName }) => (
  <div
    className="inline-block rounded-full px-2"
    style={{
      backgroundColor: getHexForColor(data.color),
      color: tinycolor(getHexForColor(data.color)).darken(40).toString(),
    }}
  >
    <p className={`${isName ? 'text-2xl' : ''} text-sm`}>{data.value}</p>
  </div>
)

interface DateValueProps {
  value: any
  includeTime: boolean
  timeFormat: string
}
const DateValue: FC<DateValueProps> = ({
  value,
  includeTime = false,
  timeFormat = '24',
}) => {
  if (includeTime && value) {
    return (
      <p className="truncate text-gray-500">
        {dayjs(value).format(
          `DD/MM/YYYY ${timeFormat === '24' ? 'HH:mm' : 'hh:mma'}`,
        )}
      </p>
    )
  } else if (value) {
    return (
      <p className="truncate text-gray-500">
        {dayjs(value).format('DD/MM/YYYY ')}
      </p>
    )
  } else {
    return null
  }
}

interface FieldRendererProps {
  field: any // not typed yet
  record: any // not typed yet
  isName: boolean
}

export const FieldRenderer: FC<FieldRendererProps> = ({
  field,
  record,
  isName,
}) => {
  let recordData
  switch (field.type) {
    case BaserowFieldType.TEXT:
    case BaserowFieldType.LONG_TEXT:
    case BaserowFieldType.EMAIL:
    case BaserowFieldType.PHONE_NUMBER:
    case BaserowFieldType.NUMBER:
    case BaserowFieldType.AUTONUMBER:
    case BaserowFieldType.COUNT:
    case BaserowFieldType.UUID: {
      return (
        <p className="text-gray-500">
          {record.getCellValueAsString(field.name)}
        </p>
      )
    }
    case BaserowFieldType.CREATED_ON:
    case BaserowFieldType.LAST_MODIFIED:
    case BaserowFieldType.DATE: {
      return (
        <DateValue
          value={record.getCellValue(field.name) ?? null}
          includeTime={field?.date_include_time}
          timeFormat={field?.date_time_format}
        />
      )
    }
    case BaserowFieldType.URL: {
      let link = record.getCellValueAsString(field.name)
      if (!link?.startsWith('http')) {
        link = `https://${link}`
      }
      return (
        <p
          role="button"
          tabIndex={0}
          className="break-all text-blue-500 hover:cursor-pointer hover:underline"
          onClick={(e) => {
            e.stopPropagation()
            openWindowWithBlockCheck(link, '_blank')
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.stopPropagation()
              openWindowWithBlockCheck(link, '_blank')
            }
          }}
        >
          {record.getCellValueAsString(field.name)}
        </p>
      )
    }
    case BaserowFieldType.FILE: {
      const files = record.getCellValueAsString(field.name)
      let fileNodes = []
      if (files?.length > 0) {
        fileNodes = files.map((file) => (
          <Tooltip title={file?.visible_name} key={file.id}>
            <div className="flex items-center justify-center rounded-lg bg-gray-200 px-1 hover:cursor-default">
              <p className="p-0.5 text-sm uppercase">
                {file?.visible_name?.split('.').pop()}
              </p>
            </div>
          </Tooltip>
        ))
        return <div className="flex gap-2">{fileNodes}</div>
      } else {
        return null
      }
    }
    case BaserowFieldType.BOOLEAN: {
      const recordValue = record.getCellValue(field.name)
      if (recordValue) {
        return (
          <div className="grid h-6 w-6 place-items-center rounded-lg bg-indigo-500">
            <CheckIcon className="h-5 w-5 text-white" />
          </div>
        )
      } else {
        return (
          <div className="h-6 w-6 rounded-lg border-2 border-indigo-500"></div>
        )
      }
    }
    case BaserowFieldType.FORMULA:
      switch (field.formula_type) {
        case BaserowFormulaType.BUTTON: {
          const buttonValue = record.getCellValue(field.name)
          const link = buttonValue.url

          return (
            <Button
              variant="outlined"
              className="max-w-sm truncate !normal-case"
              onClick={(e) => {
                e.stopPropagation()
                openWindowWithBlockCheck(link, '_blank')
              }}
            >
              {buttonValue?.label ?? 'Open'}
            </Button>
          )
        }
        case BaserowFormulaType.ARRAY:
        case BaserowFormulaType.TEXT:
        case BaserowFormulaType.DATE_INTERVAL:
        case BaserowFormulaType.DATE:
          if (field.array_formula_type === 'text') {
            return (
              <p className="text-gray-500">
                {record
                  .getCellValue(field.name)
                  .map((lookup) => {
                    let parsedLookup
                    try {
                      parsedLookup = JSON.parse(lookup.value)
                    } catch (e) {
                      parsedLookup = lookup.value
                    }
                    if (Array.isArray(parsedLookup)) {
                      return parsedLookup
                        .map((obj) => (obj && obj.value ? obj.value : null))
                        .filter(Boolean)
                    } else {
                      return parsedLookup ? [parsedLookup] : []
                    }
                  })
                  .flat()
                  .join(', ')}
              </p>
            )
          } else if (field.array_formula_type === 'boolean') {
            const booleanValue = record.getCellValue(field.name)
            return (
              <p className="text-gray-500">{booleanValue ? 'True' : 'False'}</p>
            )
          } else if (field.array_formula_type === 'button') {
            return (
              <Button
                className="max-w-sm truncate"
                onClick={(e) => {
                  e.stopPropagation()
                  const buttonValue = record.getCellValue(field.name)
                  const link = buttonValue[0].value.url
                  openWindowWithBlockCheck(link, '_blank')
                }}
              >
                Open
              </Button>
            )
          } else if (field.array_formula_type === 'date') {
            return (
              <p className="text-gray-500">
                {record
                  .getCellValue(field.name)
                  .map((lookup) => lookup.value)
                  .join(', ')}
              </p>
            )
          } else if (field.array_formula_type) {
            return (
              <p className="text-gray-500">
                {record
                  .getCellValue(field.name)
                  .map((lookup) => lookup.value)
                  .join(', ')}
              </p>
            )
          } else {
            if (field.formula_type === 'date') {
              if (field.date_include_time && record.getCellValue(field.name)) {
                return (
                  <p className="text-gray-500">
                    {new Date(record.getCellValue(field.name)).toLocaleString(
                      'en-AU',
                    )}
                  </p>
                )
              } else if (record.getCellValue(field.name)) {
                return (
                  <p className="text-gray-500">
                    {new Date(
                      record.getCellValue(field.name),
                    ).toLocaleDateString('en-AU')}
                  </p>
                )
              } else {
                return null
              }
            } else {
              return (
                <p className="text-gray-500">
                  {record.getCellValueAsString(field.name)}
                </p>
              )
            }
          }
        case BaserowFormulaType.SINGLE_SELECT:
          recordData = record.getCellValue(field.name)
          // process with FieldRenderer
          return (
            <FieldRenderer
              field={{
                name: field.name,
                type: 'single_select',
              }}
              record={record}
              isName={isName}
            />
          )
        case BaserowFormulaType.DURATION: {
          return (
            <p className="text-gray-500">
              {formatDurationValue(
                Number(record.getCellValueAsString(field.name)),
                field.duration_format,
              )}
            </p>
          )
        }
        default:
          return (
            <p className="text-gray-500">
              {record.getCellValueAsString(field.name)}
            </p>
          )
      }
    case BaserowFieldType.SINGLE_SELECT:
      recordData = record.getCellValue(field.name)
      return (
        <div>
          <ColoredChip
            data={{
              value: recordData?.value,
              color: recordData?.color,
            }}
            isName={isName}
          />
        </div>
      )
    case BaserowFieldType.MULTIPLE_SELECT:
      recordData = record.getCellValue(field.name)
      if (recordData?.length > 0) {
        return (
          <div className="flex flex-wrap gap-2">
            {recordData.map((data) => (
              <ColoredChip
                key={data.id}
                data={{
                  value: data.value,
                  color: data.color,
                }}
                isName={isName}
              />
            ))}
          </div>
        )
      } else {
        return null
      }
    case BaserowFieldType.LOOKUP: {
      const lookupValue = record.getCellValue(field.name)
      switch (field.array_formula_type) {
        case 'text':
        case 'number':
        case 'link':
        case 'char':
        case 'date_interval':
          return (
            <div className="inline-flex gap-2">
              {lookupValue.map((lookup) => {
                return lookup?.value ? (
                  <div
                    key={lookup?.id}
                    className="rounded bg-gray-100 p-1 px-1.5 text-gray-500"
                  >
                    {lookup?.value}
                  </div>
                ) : null
              })}
            </div>
          )
        case 'multiple_select':
        case 'single_select': {
          const chips = lookupValue.map((lookup) => {
            if (!lookup?.value) return null
            // if lookup.value is an array
            if (Array.isArray(lookup?.value)) {
              return (
                <div key={lookup?.id} className="flex gap-2">
                  {lookup?.value.map((value) => {
                    return (
                      <ColoredChip
                        key={value?.id}
                        data={{
                          value: value?.value,
                          color: value?.color,
                        }}
                        isName={isName}
                      />
                    )
                  })}
                </div>
              )
            }
            return (
              <ColoredChip
                key={lookup?.value?.id}
                data={{
                  value: lookup?.value?.value,
                  color: lookup?.value?.color,
                }}
                isName={isName}
              />
            )
          })
          return chips
        }
        case 'date': {
          return (
            <div className="inline-flex gap-2">
              {lookupValue?.map((date) => {
                return date?.value ? (
                  <div
                    key={date?.id}
                    className="truncate rounded bg-gray-100 p-1 px-1.5"
                  >
                    <DateValue
                      value={date?.value}
                      includeTime={field?.date_include_time}
                      timeFormat={field?.date_time_format}
                    />
                  </div>
                ) : null
              })}
            </div>
          )
        }
        case 'boolean': {
          return (
            <div className="inline-flex gap-2">
              {lookupValue?.map((boolean) => {
                if (boolean?.value) {
                  return (
                    <div
                      key={boolean?.id}
                      className="grid h-6 w-6 place-items-center rounded-lg bg-indigo-500"
                    >
                      <CheckIcon className="h-5 w-5 text-white" />
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={boolean?.id}
                      className="h-6 w-6 rounded-lg border-2 border-indigo-500"
                    ></div>
                  )
                }
              })}
            </div>
          )
        }
        case 'duration': {
          return (
            <div className="inline-flex gap-2">
              {lookupValue.map((lookup) => {
                return lookup?.value ? (
                  <div
                    key={lookup?.id}
                    className="rounded bg-gray-100 p-1 px-1.5 text-gray-500"
                  >
                    {formatDurationValue(lookup?.value, field.duration_format)}
                  </div>
                ) : null
              })}
            </div>
          )
        }
        case 'single_file': {
          let fileNodes = []
          if (lookupValue?.length > 0) {
            fileNodes = lookupValue.map((file) => (
              <Tooltip title={file?.visible_name} key={file.id}>
                <div className="flex flex-wrap items-center justify-center rounded-lg bg-gray-200 px-1 hover:cursor-default">
                  <p className="truncate p-0.5 text-sm uppercase">
                    {file?.visible_name}
                  </p>
                </div>
              </Tooltip>
            ))
            return <div className="flex flex-wrap gap-2">{fileNodes}</div>
          } else {
            return null
          }
        }
        case 'button': {
          let buttonNodes = []
          if (lookupValue?.length > 0) {
            buttonNodes = lookupValue.map((button) => (
              <Button
                variant="outlined"
                className="!normal-case"
                key={button.id}
                fullWidth={false}
                onClick={(event) => {
                  event.stopPropagation()
                  openWindowWithBlockCheck(button?.value?.url, '_blank')
                }}
              >
                {button?.value?.label ?? 'Open'}
              </Button>
            ))
            return (
              <div className="flex w-full flex-wrap gap-2 overflow-x-hidden">
                {buttonNodes}
              </div>
            )
          } else {
            return null
          }
        }
        default:
          return <p className="text-sm text-gray-400">N/A</p>
      }
    }
    case BaserowFieldType.LINK_ROW:
      recordData = record.getCellValue(field.name)
      if (recordData?.length > 0) {
        return (
          <div className="flex w-full flex-col gap-0.5" key={record.id}>
            <div
              className="mr-auto rounded-md bg-gray-200 px-2 py-1"
              key={recordData[0].id}
            >
              <p className="truncate text-sm">{recordData[0].value}</p>
            </div>
            {recordData?.length > 1 && (
              <p className="text-sm text-gray-500">
                +{recordData?.length - 1} more
              </p>
            )}
          </div>
        )
      } else {
        return null
      }
    case BaserowFieldType.MULTIPLE_COLLABORATORS:
      recordData = record.getCellValue(field.name)
      if (recordData?.length > 0) {
        return (
          <div className="flex w-full flex-col gap-0.5" key={record.id}>
            <div
              className="mr-auto flex items-center gap-2 rounded-md bg-gray-200 px-2 py-1"
              key={recordData[0].id}
            >
              <NameInitialsAvatar
                name={recordData[0].name}
                borderWidth="0px"
                bgColor="white"
                size="20px"
                textSize="10px"
              />
              <p className="truncate text-sm">{recordData[0].name}</p>
            </div>
            {recordData?.length > 1 && (
              <p className="text-sm text-gray-500">
                +{recordData?.length - 1} more
              </p>
            )}
          </div>
        )
      } else {
        return null
      }
    case BaserowFieldType.RATING: {
      return (
        <Rating
          name="read-only"
          value={record.getCellValueAsString(field.name)}
          max={field.max_value}
          readOnly
        />
      )
    }
    case BaserowFieldType.CREATED_BY:
    case BaserowFieldType.LAST_MODIFIED_BY: {
      const user = record.getCellValue(field.name)
      return (
        <div className="inline-flex w-auto flex-shrink-0 items-center gap-2 truncate rounded-md bg-gray-200 px-2 py-1">
          <NameInitialsAvatar
            name={user?.name ?? 'Unknown User'}
            borderWidth="0px"
            bgColor="white"
            size="20px"
            textSize="10px"
          />
          <p className="truncate text-sm">{user?.name ?? 'Unknown User'}</p>
        </div>
      )
    }
    case BaserowFieldType.DURATION: {
      return (
        <p className="text-gray-500">
          {formatDurationValue(
            Number(record.getCellValueAsString(field.name)),
            field.duration_format,
          )}
        </p>
      )
    }
    case BaserowFieldType.ROLLUP: {
      const value = record.getCellValueAsString(field.name)
      const rollupFunction = field?.rollup_function

      if (field?.formula_type === 'boolean') {
        return (
          <p className="capitalize">
            {rollupFunction}:&nbsp;
            <span className="text-gray-400">{value ? 'true' : 'false'}</span>
          </p>
        )
      }
      return (
        <p className="capitalize">
          {rollupFunction}:&nbsp;
          <span className="text-gray-400">{value}</span>
        </p>
      )
    }
    // Field types not yet implemented
    case BaserowFieldType.AI: // WIP
    case BaserowFieldType.PASSWORD: // WIP
    default:
      return <p className="text-sm text-gray-400">N/A</p>
  }
}

export default FieldRenderer
